import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  TransparencyCase,
  TransparencyCaseCategory,
  TransparencyCaseDto,
  getEnumOptions,
} from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-transparency-case-item-form',
  templateUrl: './transparency-case-item-form.component.html',
  styleUrl: './transparency-case-item-form.component.scss',
})
export class TransparencyCaseItemFormComponent extends BaseForm<TransparencyCaseDto> implements OnInit {
  closureStatausEnums = getEnumOptions(TransparencyCase.ClosureStatusEnum);
  publishedWBCategory = TransparencyCaseCategory.StatusEnum.Published;
  answersControl = new FormControl(null);

  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'TRANSPARENCY_CASE');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      // name: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      questionnaire: new FormControl(null, Validators.required),
      questionnaireValue: new FormControl(null, Validators.required),
      category: new FormControl(null, Validators.required),
      assignee: new FormControl(null, Validators.required),
      closureStatus: new FormControl(null, Validators.required),
      closureNote: new FormControl(null),
      description: new FormControl(null),
    });
    this.formGroup.controls.questionnaire.valueChanges.subscribe((x) => {
      this.formGroup.controls.questionnaireValue.patchValue(null);
    });
  }
}
